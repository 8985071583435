<template>
    <div>
        <Location ref='location' :siteType="2"></Location>
    </div>
</template>

<script>
import Location from '../../components/SitePoint.vue'

export default{
    data(){
        return{
        }
    },
    components:{
        Location
    },
    created(){

    },
    mounted(){
        
    },
    methods:{
    }
}
</script>

<style>
.content{
    display: flex;
    flex-direction: column;
    justify-content:flex-start;
}
.tool{
    display: flex;
    flex-direction: row;
    justify-content:flex-start;
}
.el-link{
    margin-right: 10px;
    font-size: 12px;
}
</style>